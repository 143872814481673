import * as Yup from "yup";
import { SpecialtyTypes } from "./types";

export const validationSchema = Yup.object().shape({
  isFirstTime: Yup.bool(),
  applyToAll: Yup.bool(),
  otherTypes: Yup.string().test("other_types_is_selected", function (value) {
    let isOtherTypeSelected = false;
    const obj = { ...this.parent.specialtyTypes } as SpecialtyTypes;
    for (const key in obj) {
      const currCategory = obj[key];
      if (currCategory.checked && currCategory.name === "Barang Lainnya") {
        isOtherTypeSelected = true;
        break;
      }
    }
    if (isOtherTypeSelected) {
      return value !== undefined && value.length > 0 && value.length <= 150;
    }
    return true;
  }),
  specialtyTypes: Yup.object(),
});
