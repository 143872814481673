export const numberFormatter = (
  unformattedValue: string | number,
  precision?: number
): string => {
  const unformattedString =
    typeof unformattedValue !== "string"
      ? unformattedValue.toString()
      : unformattedValue;

  const formattedString = new Intl.NumberFormat("id-ID", {
    minimumFractionDigits: 0,
    maximumFractionDigits: precision ?? 2,
  }).format(parseFloat(unformattedString));

  return formattedString;
};

export const currencyFormatter = (
  unformattedValue: string | number | null | undefined,
  currency = "IDR"
): string => {
  if (typeof unformattedValue === "undefined" || unformattedValue === null)
    return "-";

  const unformattedString =
    typeof unformattedValue !== "string"
      ? unformattedValue.toString()
      : unformattedValue;

  const formattedString = new Intl.NumberFormat("id-ID", {
    style: "currency",
    currency,
    minimumFractionDigits: 0,
    maximumFractionDigits: 2,
  }).format(parseFloat(unformattedString));

  return formattedString;
};

export const percentageFormatter = (unformattedString: string): string => {
  const floatValue = parseFloat(unformattedString);
  const roundValue = +floatValue.toFixed(2);
  return roundValue.toString() + "%";
};
