import React from "react";
import { Modal } from "@gada-saas/web-ui";
import {
  StoreSpecialtyModalProps,
  StoreSpecialtyTypeFormValues,
} from "./types";
import {
  createPutStoreSpecialtyRequestPayload,
  transformStoreSpecialtyAPIResponseData,
} from "./utils";
import { Formik } from "formik";
import StoreSpecialtyTypeModalContent from "./StoreSpecialtyTypeModalContent";
import {
  usePutStoreSpecialtyMutation,
  useStoresActions,
  useStoreSpecialtyModalState,
} from "@gada-saas/web-core";
import { validationSchema } from "./forms";
import { useStyles } from "./styles";
import { useFormikContext } from "formik";
import { useApiErrorHandler } from "@common/hooks";
import { useGetStoreSpecialtyQuery } from "@gada-saas/web-core/stores/hooks";
import { useSegmentAnalytics } from "@miscellaneous/tracking/hooks/useSegmentAnalytics";

const StoreSpecialtyTypeModalInner = ({ storeId }: { storeId: string }) => {
  const analytics = useSegmentAnalytics();
  const classes = useStyles();
  const { open, origin, showNewUserUI } = useStoreSpecialtyModalState();
  const storesActions = useStoresActions();
  const { values, submitForm, resetForm, isSubmitting } =
    useFormikContext<StoreSpecialtyTypeFormValues>();

  const atLeastOneCategoryChecked = React.useMemo(() => {
    for (const key in values.specialtyTypes) {
      if (values.specialtyTypes[key].checked) {
        return true;
      }
    }
    return false;
  }, [values.specialtyTypes]);

  React.useEffect(() => {
    if (open) {
      analytics.track(
        origin === "home"
          ? "Store Specility Homepage Entry Point Shown"
          : "Store Speciality Modal Shown",
        {
          ...(origin === "home" && {
            pop_up_condition: showNewUserUI
              ? "After Onbaording Coachmark"
              : "Outside Operating Time",
          }),

          ...(origin === "userSettings" && {
            entry_point: "Store Details",
          }),
        }
      );
    }
  }, [open, analytics, origin, showNewUserUI]);

  return (
    <Modal
      open={open}
      title={showNewUserUI ? "Satu Langkah Lagi!" : "Jenis Barang yang Dijual"}
      content={<StoreSpecialtyTypeModalContent storeId={storeId} />}
      modalContentProps={{ className: classes.modalContent }}
      modalActionProps={{ className: classes.modalActions }}
      primaryButtonProps={{
        onClick: submitForm,
        variant: "primary",
        disabled: !atLeastOneCategoryChecked || isSubmitting,
        id: "button_primary_store_specialty_type_modal",
        isLoading: isSubmitting,
      }}
      secondaryButtonProps={{
        onClick: () => {
          storesActions.resetStoreSpecialtyModalState();
          resetForm();
        },
        variant: "tertiary",
        id: "button_secondary_store_specialty_type_modal",
        disabled: isSubmitting,
      }}
      primaryButtonText="Simpan"
      secondaryButtonText={origin === "home" ? undefined : "Batal"}
    />
  );
};

const StoreSpecialtyTypeModal = ({
  onSuccess,
  storeId,
}: StoreSpecialtyModalProps) => {
  const analytics = useSegmentAnalytics();
  const [handleError] = useApiErrorHandler();
  const [putStoreSpecialty] = usePutStoreSpecialtyMutation();

  const { data: getStoreSpecialtyData } = useGetStoreSpecialtyQuery(storeId, {
    skip: !storeId,
    refetchOnMountOrArgChange: true,
  });

  const handleSubmit = async (values: StoreSpecialtyTypeFormValues) => {
    if (!storeId) return;

    let isCustomSpecialtyChecked = false;
    for (const key in values.specialtyTypes) {
      if (
        values.specialtyTypes[key].name === "Barang Lainnya" &&
        values.specialtyTypes[key].checked
      ) {
        isCustomSpecialtyChecked = true;
        break;
      }
    }

    if (isCustomSpecialtyChecked) {
      analytics.track("Custom Speciality Entered", {
        keyword: values.otherTypes,
      });
    }

    analytics.track("Store Speciality Submitted", {
      entry_point: origin === "home" ? "Homepage" : "Store Details",
      is_custom_specialty: isCustomSpecialtyChecked,
      list_speciality: Object.keys(values.specialtyTypes).reduce(
        (list, key) => {
          if (values.specialtyTypes[key].checked) {
            list.push(values.specialtyTypes[key].name);
          }
          return list;
        },
        [] as string[]
      ),
    });

    try {
      const apiResponse = await putStoreSpecialty({
        routeParams: { storeId },
        requestPayload: createPutStoreSpecialtyRequestPayload(values),
      }).unwrap();
      onSuccess?.(apiResponse);
    } catch (e) {
      handleError(e as any);
    }
  };

  return (
    <Formik<StoreSpecialtyTypeFormValues>
      initialValues={transformStoreSpecialtyAPIResponseData(
        getStoreSpecialtyData
      )}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
      enableReinitialize
    >
      <StoreSpecialtyTypeModalInner storeId={storeId} />
    </Formik>
  );
};

export default StoreSpecialtyTypeModal;
