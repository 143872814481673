import { AppTheme, pxToRem } from "@gada-saas/web-ui";
import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles<AppTheme>(({ breakpoints }) => ({
  labelRoot: {
    maxHeight: pxToRem(60),
  },
  labelSpacing: {
    paddingTop: pxToRem(9),
    paddingRight: pxToRem(9),
    paddingBottom: pxToRem(9),
    paddingLeft: pxToRem(18),
  },
  labelBorder: {
    borderRadius: pxToRem(8),
    border: "1px solid #DEDEDE",
  },
  labelReverseDirection: {
    flexDirection: "row-reverse",
  },
  hideOverflow: {
    overflow: "hidden",
  },
  labelText: {
    width: pxToRem(146),
    maxHeight: pxToRem(48),
    wordBreak: "break-word",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    "-webkit-line-clamp": 2 /* number of lines to show */,
    lineClamp: 2,
    "-webkit-box-orient": "vertical",
  },
  formGroup: {
    flexWrap: "nowrap",
    display: "grid",
    gridTemplateColumns: "auto auto",
    [breakpoints.only("xs")]: {
      gridTemplateColumns: "auto",
    },
    gap: pxToRem(12),
  },
  modalContent: {
    paddingLeft: 0,
    paddingRight: 0,
    paddingTop: pxToRem(24),
  },
  modalActions: {
    boxShadow: "0px -6px 8px rgba(0, 0, 0, 0.05)",
  },
  inputOtherTypes: {
    marginTop: pxToRem(16),
  },
  inputOtherTypesHelperText: {
    color: "#929292",
    fontWeight: 400,
    fontSize: pxToRem(12),
  },
}));
