import { TIME_24_FORMAT_REGEX } from "@common/constants";
import {
  GetStoreOperatingTimeAPIResponse,
  StoreOperatingTime,
  UpsertStoreOperatingTimeAPIRequest,
} from "@gada-saas/web-core";
import { OperatingTimes, StoreOperatingTimeFormValues } from "./types";

export const options = [
  { label: "Setiap hari", value: 0 },
  { label: "Senin", value: 1 },
  { label: "Selasa", value: 2 },
  { label: "Rabu", value: 3 },
  { label: "Kamis", value: 4 },
  { label: "Jumat", value: 5 },
  { label: "Sabtu", value: 6 },
  { label: "Minggu", value: 7 },
] as const;

export const defaultFormInitialValues: StoreOperatingTimeFormValues = {
  applyToAll: false,
  operatingTimes: {},
};

/**
 *
 * @param string
 * @param val
 * @param idx
 * @param list
 * @returns String comma separated if length list > 2.
 * Otherwise, will be separated with 'dan'.
 */
export function commaSeparatedListString(
  string: string,
  val: string,
  idx: number,
  list: string[]
) {
  // It's not last index
  if (idx < list.length - 1) {
    string +=
      list.length === 2 && idx < list.length - 1
        ? `<strong>${val}</strong> dan `
        : list.length > 2 && idx < list.length - 1
        ? `<strong>${val}</strong>, `
        : "";
  } else {
    string += `${list.length > 2 ? "dan " : ""}<strong>${val}</strong>`;
  }
  return string;
}

export function createStoreOperatingTimeRequestPayload(
  values: StoreOperatingTimeFormValues
): UpsertStoreOperatingTimeAPIRequest["requestPayload"] {
  return {
    applyToAll: values.applyToAll,
    operatingTimes: Object.keys(values.operatingTimes).reduce((list, key) => {
      const currValue = values.operatingTimes[key];
      if (currValue.finish && currValue.start) {
        list.push({
          dayOfWeek: Number(key),
          endTime: currValue.finish,
          startTime: currValue.start,
        });
      }
      return list;
    }, [] as StoreOperatingTime[]),
  };
}

export function transformStoreOperatingTimeAPIResponseData(
  storeOperatingTimeData: GetStoreOperatingTimeAPIResponse["data"] | undefined
): StoreOperatingTimeFormValues {
  const res = { ...defaultFormInitialValues };

  if (storeOperatingTimeData) {
    res.operatingTimes = {
      ...storeOperatingTimeData.operatingTimes.reduce((obj, operatingTime) => {
        obj[operatingTime.dayOfWeek] = {
          finish: operatingTime.endTime,
          start: operatingTime.startTime,
        };
        return obj;
      }, {} as OperatingTimes),
    };

    res.applyToAll = storeOperatingTimeData.applyToAll;
  }

  return { ...res };
}

export function getOperatingTimeFieldErrorCaption(value: string | undefined) {
  if (!value) return "Belum diisi";
  if (!Boolean(value.match(TIME_24_FORMAT_REGEX))) return "Format waktu salah";
  return undefined;
}
