import React from "react";
import { pxToRem } from "@gada-saas/web-ui";
import {
  StoreSpecialtyTypeFormValues,
  StoreSpecialtyTypeCheckboxProps,
} from "./types";
import { Box, Checkbox, FormControlLabel } from "@material-ui/core";
import { useStyles } from "./styles";
import clsx from "clsx";
import { useFormikContext } from "formik";

const StoreSpecialtyTypeCheckbox: React.VFC<StoreSpecialtyTypeCheckboxProps> =
  ({ label, value, imgSrc }) => {
    const classes = useStyles();
    const { values, setFieldValue } =
      useFormikContext<StoreSpecialtyTypeFormValues>();

    /**
     * ----
     * Handlers
     * ----
     */
    const handleChangeCheckbox = (
      event: React.ChangeEvent<HTMLInputElement>
    ) => {
      setFieldValue(`specialtyTypes[${value}].checked`, event.target.checked);
    };

    return (
      <FormControlLabel
        id={`checkbox_specialty_type_${value}`}
        className={clsx({
          [classes.labelRoot]: true,
          [classes.labelSpacing]: true,
          [classes.labelBorder]: true,
          [classes.labelReverseDirection]: true,
        })}
        label={
          <Box
            display="flex"
            width="100%"
            gridColumnGap={pxToRem(12)}
            alignItems="center"
          >
            <img
              className={classes.hideOverflow}
              src={imgSrc}
              alt={"category-" + value}
              width={24}
              height={24}
            />
            <div
              title={label}
              className={clsx({
                [classes.labelText]: true,
                [classes.hideOverflow]: true,
              })}
            >
              {label}
            </div>
          </Box>
        }
        control={
          <Checkbox
            checked={values.specialtyTypes[value]?.checked}
            onChange={handleChangeCheckbox}
          />
        }
      />
    );
  };

export default StoreSpecialtyTypeCheckbox;
