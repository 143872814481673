import {
  GetStoreSpecialtyAPIResponse,
  UpsertStoreSpecialtyAPIRequest,
} from "@gada-saas/web-core";
import { SpecialtyTypes, StoreSpecialtyTypeFormValues } from "./types";

export function createPutStoreSpecialtyRequestPayload(
  values: StoreSpecialtyTypeFormValues
): UpsertStoreSpecialtyAPIRequest["requestPayload"] {
  let isOtherCategoryChecked = false;
  for (const key in values.specialtyTypes) {
    const currCategory = values.specialtyTypes[key];
    if (currCategory.checked && currCategory.name === "Barang Lainnya") {
      isOtherCategoryChecked = true;
    }
  }

  return {
    applyToAll: values.applyToAll,
    others: isOtherCategoryChecked ? values.otherTypes : null,
    specialties: Object.keys(values.specialtyTypes).reduce((list, val) => {
      if (values.specialtyTypes[val].checked) {
        list.push(val);
      }
      return list;
    }, [] as string[]),
  };
}

const defaultFormInitialValues: StoreSpecialtyTypeFormValues = {
  applyToAll: false,
  otherTypes: "",
  specialtyTypes: {},
};

export function transformStoreSpecialtyAPIResponseData(
  apiResponse: GetStoreSpecialtyAPIResponse | undefined
): StoreSpecialtyTypeFormValues {
  const res = { ...defaultFormInitialValues };
  if (apiResponse) {
    res.specialtyTypes = {
      ...(apiResponse.data.specialties.reduce((obj, val) => {
        obj[val.id] = {
          checked: val.selected,
          name: val.name,
        };
        return obj;
      }, {} as SpecialtyTypes) || {}),
    };
    res.otherTypes = apiResponse.data.others || "";
    res.applyToAll = apiResponse.data.applyToAll || false;
  }
  return { ...res };
}
