import React from "react";
import { pxToRem, Text, TextField } from "@gada-saas/web-ui";
import { StoreSpecialtyTypeFormValues } from "./types";
import {
  Box,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormHelperText,
} from "@material-ui/core";
import { useStyles } from "./styles";
import { useFormikContext } from "formik";
import StoreSpecialtyTypeCheckbox from "./StoreSpecialtyTypeCheckbox";
import { useGetUserInfoQuery, UserStoreRole } from "@gada-saas/web-core";
import parse from "html-react-parser";
import { commaSeparatedListString } from "../StoreOperatingTimeModal/utils";
import StoreApi from "@gada-saas/web-core/stores/hooks";

const StoreOperatingTimeModalContent = ({ storeId }: { storeId: string }) => {
  const { values, handleChange, handleBlur, errors, touched, isValid, dirty } =
    useFormikContext<StoreSpecialtyTypeFormValues>();
  const classes = useStyles();
  const { data } = useGetUserInfoQuery();
  const currentStore = data?.data.userStoreList.find(
    (s) => s.storeId === storeId
  );

  const { currentData: getStoreSpecialtyData } =
    StoreApi.endpoints.getStoreSpecialty.useQueryState(storeId || "");

  const options =
    getStoreSpecialtyData?.data.specialties.map((v) => ({
      value: v.id,
      label: v.name,
      iconUrl: v.iconUrl,
    })) || [];

  const otherStoresWhoseUserIsAnOwner =
    data?.data.userStoreList.filter((store) => {
      const storeRole = store.storeRoleList[0];
      return (
        store.storeName !== currentStore?.storeName &&
        storeRole.name === UserStoreRole.OWNER
      );
    }) || [];

  const otherStoresName = otherStoresWhoseUserIsAnOwner
    .map((store) => store.storeName)
    .reduce(commaSeparatedListString, "");

  const isOtherCategoryChecked = React.useMemo(() => {
    for (const key in values.specialtyTypes) {
      const currCategory = values.specialtyTypes[key];
      if (currCategory.checked && currCategory.name === "Barang Lainnya") {
        return true;
      }
    }
    return false;
  }, [values.specialtyTypes]);

  const inputOtherTypesRef = React.useRef<HTMLInputElement | null>(null);

  React.useEffect(() => {
    let id: string | null = null;
    // Get the id
    for (const key in values.specialtyTypes) {
      const curr = values.specialtyTypes[key];
      if (curr.name === "Barang Lainnya" && curr.checked) {
        id = key;
        break;
      }
    }
    // Scroll to the input
    if (
      !isValid &&
      errors.otherTypes &&
      id !== null &&
      touched?.specialtyTypes &&
      touched.specialtyTypes[id] !== undefined
    ) {
      inputOtherTypesRef.current?.scrollIntoView({
        block: "start",
        inline: "nearest",
      });
    }
  }, [isValid, dirty, errors, touched, values.specialtyTypes]);

  return (
    <>
      <Box paddingLeft={pxToRem(24)} paddingRight={pxToRem(24)}>
        <Text variant="body1" style={{ marginBottom: pxToRem(24) }}>
          Pilih semua jenis barang yang Anda jual di{" "}
          <strong>{currentStore?.storeName || "-"}</strong> saat ini agar kami
          bisa berikan rekomendasi yang tepat bagi Anda.
        </Text>
        <FormGroup className={classes.formGroup}>
          {options.map((option, index) => (
            <StoreSpecialtyTypeCheckbox
              key={index}
              label={option.label}
              value={option.value}
              imgSrc={option.iconUrl}
            />
          ))}
        </FormGroup>

        {isOtherCategoryChecked ? (
          <TextField
            inputRef={inputOtherTypesRef}
            id="input_other_types"
            name="otherTypes"
            value={values.otherTypes}
            placeholder="Ketik jenis barang..."
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.inputOtherTypes}
            error={Boolean(errors.otherTypes) && Boolean(touched.otherTypes)}
            helperText={
              <Box display="flex" justifyContent="space-between">
                <FormHelperText
                  error={
                    Boolean(errors.otherTypes) &&
                    Boolean(touched.otherTypes) &&
                    !values.otherTypes
                  }
                  className={classes.inputOtherTypesHelperText}
                >
                  {Boolean(errors.otherTypes) &&
                  Boolean(touched.otherTypes) &&
                  !values.otherTypes
                    ? "Harus diisi jika Anda memilih “Barang Lainnya”"
                    : "Pisahkan jenis barang dengan koma. Misal: lampu, pulsa"}
                </FormHelperText>
                <FormHelperText
                  error={
                    Boolean(errors.otherTypes) &&
                    Boolean(touched.otherTypes) &&
                    values.otherTypes.length > 150
                  }
                  className={classes.inputOtherTypesHelperText}
                >
                  {values.otherTypes.length}/150
                </FormHelperText>
              </Box>
            }
          />
        ) : null}
      </Box>

      {otherStoresWhoseUserIsAnOwner.length > 0 ? (
        <Box paddingLeft={pxToRem(15)} paddingTop={pxToRem(16)}>
          <FormControlLabel
            label={<Text>Berlaku juga untuk {parse(otherStoresName)}</Text>}
            control={
              <Checkbox
                id="checkbox_operating_time_apply_to_all"
                name="applyToAll"
                checked={values.applyToAll}
                onChange={handleChange}
              />
            }
          />
        </Box>
      ) : null}
    </>
  );
};

export default StoreOperatingTimeModalContent;
