import { shallowEqual, useSelector } from "react-redux";
import { AppStateType } from "../store";

export const useStoreState = (): AppStateType["storeReducer"] =>
  useSelector((state: AppStateType) => state.storeReducer, shallowEqual);

export const useSelectedStoreIdState = (): string =>
  useSelector((state: AppStateType) => state.storeReducer.selectedStoreId);

export const useOpenStoreOperatingTimeModalState = (): boolean =>
  useSelector(
    (state: AppStateType) => state.storeReducer.openStoreOperatingTimeModal
  );

export const useStoreSpecialtyModalState = (): AppStateType["storeReducer"]["storeSpecialtyModal"] =>
  useSelector((state: AppStateType) => state.storeReducer.storeSpecialtyModal);
